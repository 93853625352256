import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Footer from '../components/Footer';

// TODO: make normal 404 page
export default ({ location }) => (
  <Layout location={location}>
    <h1>Strona o podanym adresie nie istnieje</h1>
    
    <Link to="">Wróć do strony głównej</Link>
      
    <Footer />
  </Layout>
);
